.page1 {
  box-sizing: border-box;
  padding: 38px 0 0 0;
  background: url(/images/solutionsBg.svg) center center / cover;
  position: relative;

  &::after {
    content: "";
    border-bottom: 278px solid #f2f8ff;
    border-left: 100vw solid transparent;
    width: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }
}
.logo_wrapper {
  margin: 0px auto 38px;
  max-width: 1220px;
  padding: 0 20px;
  box-sizing: border-box;
}
.logo {
  width: 181px;
  height: 30px;
  display: block;
}

.banner {
  max-width: 1220px;
  //   padding: 24px;
  padding: 57px 20px 278px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  z-index: 1;
  gap: 20px;
  //   flex-wrap: wrap;
}

.banner_left {
  width: 750px;
}
.sub_title {
  color: var(--Color-main-, #011127);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}
.title {
  margin-top: 20px;
  color: #011127;
  /* 标题50px */
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 70px */
}

.banner_right {
  width: 348px;
  height: 440px;
  position: relative;
}

.page2 {
  padding: 80px 0;
  background: var(--_3, #f2f8ff);
}
.page2_title {
  max-width: 1220px;
  margin: 0 auto;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  background: linear-gradient(90deg, #095be8 2.07%, #00e29e 71%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0 20px;
  box-sizing: border-box;
}

.page2_desc {
  max-width: 1220px;
  margin: 0 auto;
  color: var(--Color-main-, #011127);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  margin-top: 40px;
  padding: 0 20px;
  box-sizing: border-box;
}

.page3 {
  padding: 128px 0;
  background: linear-gradient(331deg, #eff7ff 1.09%, #fff 67.94%);
}
.page3_title {
  max-width: 1220px;
  margin: 0 auto;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 56px */
  background: linear-gradient(90deg, #095be8 2.07%, #00e29e 71%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0 20px;
  box-sizing: border-box;
}
.page3_list {
  display: flex;
  flex-direction: column;
  max-width: 1220px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
  .model:last-child {
    margin-bottom: 0;
  }
  .model:nth-child(even) {
    .content {
      order: 1;
    }
  }
  .model {
    margin-bottom: 80px;
    gap: 30px;
    display: flex;
    align-items: center;
    .content {
      .contentTitle {
        color: #011127;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;
      }
      .contentKey {
        color: #011127;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 27px */
      }

      .contentVal {
        color: #546678;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        margin-bottom: 10pxl;
      }
      .contentVal:last-of-type {
        margin-bottom: 0;
      }
    }

    .img {
      width: 370px;
      height: 328px;
      position: relative;
    }
  }
}

.practical {
  padding: 100px 0 80px;
  background: var(--Color-, #fff);
  .practical_title {
    color: #011127;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 1220px;
    padding: 0 20px;
    box-sizing: border-box;
    margin: 0 auto 60px;
  }

  .gap {
    margin: 0 auto;
    max-width: 948px;
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    .green {
      background-color: var(--color, #ccf8ec);
    }
    .blue {
      background-color: var(--color, #e4eeff);
    }
    .purple {
      background-color: var(--color, #e9e3ff);
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .model {
        flex: 1;
        padding: 30px 30px 124px;
        border-radius: 16px;
        .modelKey {
          color: #011127;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 20px;
        }
        .modelVal {
          color: #011127;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
        }
      }
      .rectangle {
        box-sizing: border-box;
        min-height: 124px;
        width: auto;
        margin: 0 30px;
        padding: 30px;
        border-radius: 16px;
        background-color: var(--color, #fff);
        text-align: center;
        transform: translateY(-50%);
        box-shadow: 0px 30px 60px -20px #7a7a7a33;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .img {
          img {
            height: getCalcNew(35, 60);
          }
          img + img {
            margin-left: -4px;
          }
          .onlyImg {
            height: getCalcNew(48, 96) !important;
          }
        }
        .bottom {
          margin-top: 20px;
        }
      }
    }
  }

  .btnWarp {
    margin-top: getCalcNew(60, 60);
    width: calc(getCalcNew(296, 506) * 3 + getCalcNew(30, 50) * 2);
    .contentBtn {
      margin: auto;
    }
  }
}

.page5 {
  background: var(--Color-, #fff);
  padding: 0 0 160px;
}
.page5_title {
  max-width: 1220px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto 60px;
  color: #011127;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.page5_list {
  max-width: 1220px;

  margin: 0 auto 20px;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  &:nth-child(odd) {
    .page5_item_left {
      order: 1;
    }
  }
}
.page5_item {
  padding: 24px;
  border-radius: 16px;
  box-sizing: border-box;
}
.page5_item_left {
  width: 756px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background: var(--_3, #f2f8ff);
}

.page5_item_right {
  width: 394px;
  border-radius: 16px;
  background: #f3f6f9;
}
.content_title {
  color: var(--_2, #011127);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
}
.content_desc {
  margin-top: 20px;
  color: var(--_1, #546678);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.content_left {
  max-width: 492px;
}

.content_right {
  position: relative;
  width: 200px;
  height: 200px;
  flex: none;
}

.footer {
  display: flex;
  width: 100%;
  padding: 60px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  background: #011127;
  box-sizing: border-box;
  .footer_content {
    max-width: 1220px;
    padding: 0 20px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
  }
}
.footer_logo {
  width: 172.941px;
  height: 28px;
}
.certification_text {
  color: #ccd0df;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
  margin: 40px 0 20px;
}
.certification_wrapper {
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.certification_logo_wrapper {
  display: flex;
  column-gap: 100px;
  row-gap: 20px;
  flex-wrap: wrap;
}
.certification_logo {
  width: 70px;
  height: 70px;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
  }
}
.footer_media {
  width: 20px;
  height: 20px;
  margin-right: 26px;
  &:last-of-type {
    margin-right: 0;
  }
}

.copyRight {
  color: var(--Color-, #546678);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  text-align: center;
}

@media screen and (max-width: 800px) {
  .page1 {
    box-sizing: border-box;
    padding: 38px 0 0 0;
    background: url(/images/solutionsBg.svg) center center / cover;
    position: relative;
    &::after {
      content: "";
      border-bottom: 20px solid #f2f8ff;
      border-left: 100vw solid transparent;
      width: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
    }
  }
  .title {
    font-size: 40px;
  }
  .banner {
    padding: 40px 20px 40px;
    margin: 0 auto;
    display: block;
  }

  .banner_left {
    width: 100%;
  }
  .page2,
  .page3 {
    padding: 60px 0;
  }
  .page3_title {
    margin-bottom: 20px;
    font-size: 40px;
  }
  .page3_list {
    display: block;
    .model {
      margin-bottom: 60px;
      display: block;
    }
  }
  .practical {
    padding: 60px 20px;
    .gap {
      margin: 0 auto;
      max-width: 948px;
      display: block;
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      .green {
        background-color: var(--color, #ccf8ec);
      }
      .blue {
        background-color: var(--color, #e4eeff);
      }
      .purple {
        background-color: var(--color, #e9e3ff);
      }
    }
  }
  .page5_list {
    display: block;
    .page5_item {
      max-width: 100%;
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
    .content_left {
      max-width: 100%;
      width: 100%;
    }
  }
}
